import React from 'react';
import Me from 'components/Me';
// eslint-disable-next-line import/no-cycle
import Work from 'components/Work';
import SmartHome from 'components/SmartHome';
import Message from 'components/Message';
import Sockets from 'components/Sockets';
import SocketsLeo from 'components/SocketsLeo';
import YoutubeChromecast from 'components/YoutubeChromecast';
import Login from 'components/Login';
import Register from 'components/Register';
import Admin from 'components/Admin';
import AGB from 'components/compulsory/AGB';
import Datenschutz from 'components/compulsory/Datenschutz';
import Impressum from 'components/compulsory/Impressum';
import Widerruf from 'components/compulsory/Widerruf';
import morocco from 'images/thumbnails/morocco.png';
import drosten from 'images/thumbnails/drosten.png';
import brauneck from 'images/thumbnails/brauneck.png';

export const VIDEOS = 3;

export const NAMES = [
  {
    path: '/work/drosten',
    render: <Work title="throwback 2020" thumbnail={drosten} link="TKldNS0kxOk" />,
    protected: false,
    name: 'Work',
  },
  {
    path: '/work/brauneck',
    render: <Work title="brauneck.mp4" thumbnail={brauneck} link="kCAc5DzfB4E" />,
    protected: false,
    name: 'Work',
  },
  {
    path: '/work/morocco',
    render: <Work title="roadtripin' morocco" thumbnail={morocco} link="jzfqMwI_Bbk" />,
    protected: false,
    name: 'Work',
  },
  {
    path: '/work',
    render: <Work title="throwback 2020" thumbnail={drosten} link="TKldNS0kxOk" />,
    protected: false,
    name: 'Work',
  },
  {
    path: '/smarthome',
    render: <SmartHome />,
    protected: false,
    name: 'Smarthome',
  },
  {
    path: '/message',
    render: <Message />,
    protected: false,
    name: 'Message',
  },
  {
    path: '/ichheisseleonieundbinfaul',
    render: <SocketsLeo />,
    protected: false,
    name: 'Leos Faulheit',
  },
  {
    path: '/<3',
    render: <YoutubeChromecast />,
    protected: false,
    name: '<3',
  },
  {
    path: '/sockets',
    render: <Sockets />,
    protected: false,
    name: 'Sockets',
  },
  {
    path: '/login',
    render: <Login />,
    protected: false,
    name: 'Login',
  },
  {
    path: '/admin',
    render: <Admin />,
    protected: true,
    name: 'Admin',
  },
  {
    path: '/register',
    render: <Register />,
    protected: false,
    name: 'Register',
  },
  {
    path: '/agb',
    render: <AGB />,
    protected: false,
    name: 'AGB',
  },
  {
    path: '/datenschutz',
    render: <Datenschutz />,
    protected: false,
    name: 'Datenschutz',
  },
  {
    path: '/impressum',
    render: <Impressum />,
    protected: false,
    name: 'Impressum',
  },
  {
    path: '/widerruf',
    render: <Widerruf />,
    protected: false,
    name: 'Widerruf',
  },
  {
    path: '/',
    render: <Me />,
    protected: false,
    name: 'About me',
  },
];
