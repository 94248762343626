import { Box } from 'grommet';
import React, { useState } from 'react';
import { Route, RouteComponentProps, RouteProps, withRouter } from 'react-router';
import UserService from 'services/UserService';
import Login from './Login';

const userService = UserService.getInstance();
const ProtectedRoute: React.FunctionComponent<RouteProps & RouteComponentProps> = (
  props: RouteProps & RouteComponentProps
) => {
  const [isAuthenticated, setIsAuthenticated] = useState('');
  userService.isAuthenticated().then((result) => {
    setIsAuthenticated(result.toString());
  });
  if (isAuthenticated === 'true') return <Route {...props} />;
  if (isAuthenticated === 'false') return <Route render={() => <Login />} />;
  return <Box className="contentAnimation" />;
};
export default withRouter(ProtectedRoute);
