import React, { useEffect, useState } from 'react';
import { Box, CheckBox, ResponsiveContext } from 'grommet';
import leonie from 'images/leonie.png';

function resolveData(data: string) {
  const power = data.slice(16, 17);
  // eslint-disable-next-line radix
  const powerBoolean = Boolean(parseInt(power));
  return powerBoolean;
}

const SocketsLeo = () => {
  const size = React.useContext(ResponsiveContext);
  let height = '403';
  let width = '302';
  if (size === 'small' || size === 'xsmall') {
    height = '360';
    width = '270';
  }
  const [data82, setData82] = useState(['', false]);

  async function switchLight(ip: string) {
    await fetch(`https://api.kiliankunkel.de/socket/${ip}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({}),
    });
  }

  useEffect(() => {
    fetch('https://api.kiliankunkel.de/socket/192.168.178.82')
      .then((res) => res.json())
      .then((resData) => {
        setData82([resData.message, resolveData(resData.message)]);
      });
  }, []);

  return (
    <Box align="center" gap="small">
      <Box align="center" pad="large" gap="small">
        <CheckBox
          label="Ich heiße Leonie und bin zu faul meinen Lichtschalter zu drücken"
          checked={data82[1] as boolean}
          onChange={(event) => {
            setData82([data82[0], !data82[1]]);
            switchLight('192.168.178.82');
          }}
          toggle
        />
        <Box margin={{ top: 'large' }}>
          <img src={leonie} alt={` | This is one of my movies | `} height={height} width={width} />
        </Box>
      </Box>
    </Box>
  );
};
export default SocketsLeo;
