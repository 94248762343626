import { Box } from 'grommet';
import React from 'react';
import { Route, Switch } from 'react-router';
import Error from 'components/Error';
import { NAMES } from 'constants/routs';
import ProtectedRoute from './ProtectedRoute';
import TabTitle from './TabTitle';

interface RoutRendererProps {}

const RoutRenderer: React.FC<RoutRendererProps> = () => {
  const allRoutes = NAMES.map((item, index) => {
    if (item.protected) {
      return (
        <ProtectedRoute
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          exact
          path={item.path}
          render={() => (
            <Box className="contentAnimation">
              <TabTitle title={item.name} />
              {item.render}
            </Box>
          )}
        />
      );
    }
    return (
      <Route
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        exact
        path={item.path}
        render={() => (
          <Box className="contentAnimation">
            <TabTitle title={item.name} />
            {item.render}
          </Box>
        )}
      />
    );
  });
  return (
    <Box margin={{ bottom: '40px' }}>
      <Switch>
        {allRoutes}
        <Route render={() => <Error />} />
      </Switch>
    </Box>
  );
};
export default RoutRenderer;
