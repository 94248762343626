import Cookies from 'universal-cookie';

class UserService {
  private static instance: UserService;
  static getInstance(): UserService {
    if (!this.instance) this.instance = new UserService();
    return this.instance;
  }
  cookies = new Cookies();
  credentials = this.cookies.get('credentials');

  async isAuthenticated() {
    let is = false;
    try {
      await fetch('https://api.kiliankunkel.de/secret', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.credentials.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (
            result.answer ===
            'efwubewofubweofubwesdklnljfnowdebcijwpojdsojqedpiwhfpwoeugfio247t3z8erg0upihofvdcg9ew2398fcuhwb9283gfueb'
          ) {
            is = true;
          } else {
            is = false;
          }
        });
    } catch {
      is = false;
    }
    return is;
  }
}

export default UserService;
