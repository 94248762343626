import { Box, Button, Form, FormField, Heading, Text, TextInput } from 'grommet';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faPaperPlane, faRedo, faTint } from '@fortawesome/free-solid-svg-icons';

interface MessageProps {}

const Message: React.FunctionComponent<MessageProps & RouteComponentProps> = (
  props: MessageProps & RouteComponentProps
) => {
  const [text, setText] = useState({ message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [secondsToRedirect, setSecondsToRedirect] = useState(2);
  const [thankYou, setThankYou] = useState('Thanks for your message!');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function sendText(value: any) {
    const data = { message: `"${value.message}"` };
    await fetch('https://api.kiliankunkel.de/message', {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
  async function sendBonfire(seconds: string) {
    const data = { seconds: `"${seconds}"` };
    await fetch('https://api.kiliankunkel.de/bonfire', {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  async function sendFountain(ip: string) {
    await fetch(`https://api.kiliankunkel.de/socket/${ip}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({}),
    });
  }

  if (submitted) {
    if (secondsToRedirect === 0) {
      props.history.push('/');
    }
    setTimeout(() => {
      setSecondsToRedirect(secondsToRedirect - 1);
    }, 1000);
  }

  return (
    <Box margin="auto" width={{ max: 'large' }}>
      {!submitted && (
        <Box>
          <Heading textAlign="center" level="3">
            🔥_________________________🔥
            <br />
            Turn on the bonfire in my room:
            <br />
            🔥¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯🔥
          </Heading>
          <Box width="small" alignSelf="center">
            <Button
              size="medium"
              label="1 minute"
              icon={<FontAwesomeIcon icon={faFire} />}
              onClick={() => {
                sendBonfire('60');
                setThankYou('Thanks for keeping me warm 🔥');
                setSubmitted(true);
              }}
            />
          </Box>
          <Heading textAlign="center" level="3" margin={{ top: 'xlarge' }}>
            💦_________________________💦
            <br />
            Turn on the fountain in my room:
            <br />
            💦¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯💦
          </Heading>
          <Box width="small" alignSelf="center">
            <Button
              size="medium"
              label="1 minute"
              icon={<FontAwesomeIcon icon={faTint} />}
              onClick={() => {
                sendFountain('192.168.178.91');
                setThankYou('Thanks for refreshing my room 💦');
                setSubmitted(true);
              }}
            />
          </Box>
          <Heading textAlign="center" level="3" margin={{ top: 'xlarge' }}>
            ✉️_________________________✉️
            <br />
            Or send me a message <br />
            ✉️¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯¯✉️
          </Heading>
          <Text textAlign="center">it will be displayed on a screen in my room ;)</Text>
          <Form
            onSubmit={() => {
              sendText(text);
              setText({ message: '' });
              setSubmitted(true);
            }}
            value={text}
            onChange={(nextValue) => setText(nextValue as { message: string })}
            onReset={() => setText({ message: '' })}
          >
            <FormField>
              <TextInput id="text-input-id" name="message" />
            </FormField>
            <Box direction="row" gap="medium" justify="center">
              <Button type="reset" icon={<FontAwesomeIcon icon={faRedo} />} />
              <Button type="submit" label="Submit" icon={<FontAwesomeIcon icon={faPaperPlane} />} />
            </Box>
          </Form>
        </Box>
      )}
      {submitted && (
        <Box>
          <Heading className="contentAnimation" textAlign="center">
            {thankYou}
          </Heading>
          <Text alignSelf="center">redirect in:</Text>
          <Text alignSelf="center">{secondsToRedirect}</Text>
        </Box>
      )}
    </Box>
  );
};
export default withRouter(Message);
