import { Box, Text } from 'grommet';
import React from 'react';

const Datenschutz = () => (
  <Box margin="auto" width={{ max: 'large' }}>
    <Text wordBreak="break-word">
      <h1>Datenschutz&shy;erkl&auml;rung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
        sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem
        Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>{' '}
      <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>{' '}
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
        Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo;
        in dieser Datenschutzerkl&auml;rung entnehmen.
      </p>{' '}
      <h4>Wie erfassen wir Ihre Daten?</h4>{' '}
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
        sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>{' '}
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
        unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
        automatisch, sobald Sie diese Website betreten.
      </p>{' '}
      <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{' '}
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
        gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet
        werden.
      </p>{' '}
      <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{' '}
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und
        Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
        Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine
        Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung
        jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter
        bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
        Aufsichtsbeh&ouml;rde zu.
      </p>{' '}
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns
        wenden.
      </p>
      <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{' '}
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
        geschieht vor allem mit sogenannten Analyseprogrammen.
      </p>{' '}
      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
        Datenschutzerkl&auml;rung.
      </p>
      <h2>2. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
      <h3>Datenschutz</h3>{' '}
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir
        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
      </p>{' '}
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
        Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden
        k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir
        erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
        geschieht.
      </p>{' '}
      <p>
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der
        Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
        der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>{' '}
      <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>{' '}
      <p>
        Herr Kilian Kunkel
        <br />
        Am Winacker 10
        <br />
        83646 Bad T&ouml;lz
      </p>
      <p>
        Telefon: 017663127616
        <br />
        E-Mail: kiliankunkel@gmx.de
      </p>
      <p>
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder
        gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen
        Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
      </p>
      <h3>Speicherdauer</h3>{' '}
      <p>
        Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt
        wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
        Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen
        oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
        sofern wir keinen anderen rechtlich zul&auml;ssigen&nbsp; Gr&uuml;nde f&uuml;r die
        Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser
        Gr&uuml;nde.
      </p>
      <h3>Hinweis zur Datenweitergabe in die USA</h3>{' '}
      <p>
        Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA
        eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogenen Daten an die
        US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
        USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind
        dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne
        dass Sie als Betroffener hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Beh&ouml;rden (z.B. Geheimdienste) Ihre auf US-Servern
        befindlichen Daten zu &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
        speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{' '}
      <p>
        Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung
        m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die
        Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
        Widerruf unber&uuml;hrt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen
        Direktwerbung (Art. 21 DSGVO)
      </h3>{' '}
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
        HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
        ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES
        GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
        DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
        PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
        SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN,
        RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
        AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
        DSGVO).
      </p>{' '}
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE
        DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
        DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
        SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
        PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
        (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
      </p>
      <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>{' '}
      <p>
        Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht
        bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
        Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu.
        Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
        gerichtlicher Rechtsbehelfe.
      </p>
      <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{' '}
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung
        eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
        g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
        &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </p>
      <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{' '}
      <p>
        Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
        Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte
        Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo;
        auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>{' '}
      <p>
        Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an
        uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, L&ouml;schung und Berichtigung</h3>{' '}
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
        unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft
        und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung
        oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
      </p>
      <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{' '}
      <p>
        Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
        zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf
        Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
      </p>{' '}
      <ul>
        {' '}
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
          ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
          der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>{' '}
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig
          geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der
          Datenverarbeitung verlangen.
        </li>{' '}
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur
          Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen,
          haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>{' '}
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
          Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
          feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung
          der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>{' '}
      </ul>{' '}
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben,
        d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer
        Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder
        juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der
        Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>{' '}
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur
        &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und
        Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
        ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-E-Mails, vor.
      </p>
      <h2>3. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>{' '}
      <p>
        Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine
        Textdateien und richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder
        vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder dauerhaft
        (permanente Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden nach Ende
        Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem Endger&auml;t
        gespeichert, bis Sie diese selbst l&ouml;schen&nbsp;oder eine automatische L&ouml;schung
        durch Ihren Webbrowser erfolgt.
      </p>{' '}
      <p>
        Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem Endger&auml;t gespeichert
        werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese erm&ouml;glichen uns
        oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>{' '}
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da
        bestimmte Websitefunktionen ohne diese nicht funktionieren w&uuml;rden (z.B. die
        Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das
        Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.
      </p>{' '}
      <p>
        Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs (notwendige
        Cookies) oder zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen
        (funktionale Cookies, z. B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website
        (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art.
        6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
        Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
        ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die
        Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies
        informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r
        bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen
        der Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies
        kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
      </p>{' '}
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie
        hier&uuml;ber im Rahmen dieser Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
        Einwilligung abfragen.
      </p>
      <h3>Server-Log-Dateien</h3>{' '}
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
        Server-Log-Dateien, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
      </p>{' '}
      <ul>
        {' '}
        <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>{' '}
        <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li>{' '}
        <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
      </ul>{' '}
      <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>{' '}
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
        und der Optimierung seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
        werden.
      </p>
      <h3>Kontaktformular</h3>{' '}
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
        Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
        Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
        nicht ohne Ihre Einwilligung weiter.
      </p>{' '}
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
        Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur
        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
        F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
        Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
      </p>{' '}
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
        L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
        f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung
        Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen
        &ndash; bleiben unber&uuml;hrt.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{' '}
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
        aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der
        Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
        ohne Ihre Einwilligung weiter.
      </p>{' '}
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
        Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur
        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
        F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
        Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
      </p>{' '}
      <p>
        Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie
        uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
        Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
        Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
        gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
      </p>
      <h3>Hubspot CRM</h3>{' '}
      <p>
        Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot Inc. 25 Street, Cambridge,
        MA 02141 USA (nachfolgend Hubspot CRM).
      </p>{' '}
      <p>
        Hubspot CRM erm&ouml;glicht es uns unter anderem, bestehende und potenzielle Kunden sowie
        Kundenkontakte zu verwalten. Mit Hilfe von Hubspot CRM sind wir in der Lage,
        Kundeninteraktionen per E-Mail, Social Media oder Telefon &uuml;ber verschiedene Kan&auml;le
        hinweg zu erfassen, zu sortieren und zu analysieren. Die so erfassten personenbezogenen
        Daten k&ouml;nnen ausgewertet und f&uuml;r die Kommunikation mit dem potenziellen Kunden
        oder f&uuml;r Marketingma&szlig;nahmen (z.B. Newslettermailings) verwendet werden. Mit
        Hubspot CRM sind wir ferner in der Lage, das Nutzerverhalten unserer Kontakte auf unserer
        Website zu erfassen und zu analysieren.
      </p>{' '}
      <p>
        Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst effizienten
        Kundenverwaltung und Kundenkommunikation. Sofern eine entsprechende Einwilligung abgefragt
        wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Details entnehmen Sie der Datenschutzerkl&auml;rung von Hubspot:{' '}
        <a
          href="https://legal.hubspot.com/de/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.hubspot.com/de/privacy-policy
        </a>
        .
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://www.hubspot.de/data-privacy/privacy-shield"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.hubspot.de/data-privacy/privacy-shield
        </a>
        .
      </p>
      <h4>Vertrag &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit Hubspot CRM geschlossen. Hierbei
        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass Hubspot CRM die personenbezogenen Daten unserer Websitebesucher nur
        nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Zoho CRM</h3>{' '}
      <p>
        Wir nutzen Zoho CRM auf dieser Website. Anbieter ist Zoho Corporation Pvt. Ltd., Estancia IT
        Park, Plot No. 140 151, GST Road, Vallancherry Village, Chengalpattu Taluk, Kanchipuram
        District 603 202, Indien (nachfolgend &bdquo;Zoho CRM&ldquo;).
      </p>{' '}
      <p>
        Zoho CRM erm&ouml;glicht es uns unter anderem, bestehende und potenzielle Kunden sowie
        Kundenkontakte zu verwalten und Vertriebs- und Kommunikationsprozesse zu organisieren. Der
        Einsatz des CRM-Systems erm&ouml;glicht uns ferner die Analyse und Optimierung unserer
        kundenbezogenen Prozesse. Die Kundendaten werden auf den Servern von Zoho CRM gespeichert.
        Details zu den Funktionen von Zoho CRM finden Sie hier:{' '}
        <a
          href="https://www.zoho.com/de/crm/help/getting-started/key-features.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zoho.com/de/crm/help/getting-started/key-features.html
        </a>
        .
      </p>{' '}
      <p>
        Die Verwendung von Zoho CRM erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst effizienten
        Kundenverwaltung und Kundenkommunikation. Sofern eine entsprechende Einwilligung abgefragt
        wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in Drittstaaten au&szlig;erhalb der Europ&auml;ischen Union wird
        auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://www.zoho.com/privacy/dpa/DPA_zoho_com.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zoho.com/privacy/dpa/DPA_zoho_com.pdf
        </a>
        .
      </p>{' '}
      <p>
        Details entnehmen Sie der Datenschutzerkl&auml;rung von Zoho CRM:{' '}
        <a href="https://www.zoho.com/privacy.html" target="_blank" rel="noopener noreferrer">
          https://www.zoho.com/privacy.html
        </a>{' '}
        und{' '}
        <a href="https://www.zoho.com/gdpr.html" target="_blank" rel="noopener noreferrer">
          https://www.zoho.com/gdpr.html
        </a>
        .
      </p>
      <h4>Vertrag &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit Zoho CRM geschlossen. Hierbei
        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass Zoho CRM die personenbezogenen Daten unserer Websitebesucher nur
        nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Kommentar&shy;funktion auf dieser Website</h3>{' '}
      <p>
        F&uuml;r die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben
        zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym
        posten, der von Ihnen gew&auml;hlte Nutzername gespeichert.
      </p>
      <h4>Speicherung der IP-Adresse</h4>{' '}
      <p>
        Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da
        wir Kommentare auf dieser Website nicht vor der Freischaltung pr&uuml;fen, ben&ouml;tigen
        wir diese Daten, um im Falle von Rechtsverletzungen wie Beleidigungen oder Propaganda gegen
        den Verfasser vorgehen zu k&ouml;nnen.
      </p>
      <h4>Abonnieren von Kommentaren</h4>{' '}
      <p>
        Als Nutzer der Seite k&ouml;nnen Sie nach einer Anmeldung Kommentare abonnieren. Sie
        erhalten eine Best&auml;tigungsemail, um zu pr&uuml;fen, ob Sie der Inhaber der angegebenen
        E-Mail-Adresse sind. Sie k&ouml;nnen diese Funktion jederzeit &uuml;ber einen Link in den
        Info-Mails abbestellen. Die im Rahmen des Abonnierens von Kommentaren eingegebenen Daten
        werden in diesem Fall gel&ouml;scht; wenn Sie diese Daten f&uuml;r andere Zwecke und an
        anderer Stelle (z.&nbsp;B. Newsletterbestellung) an uns &uuml;bermittelt haben, verbleiben
        diese Daten jedoch bei uns.
      </p>
      <h4>Speicherdauer der Kommentare</h4>{' '}
      <p>
        Die Kommentare und die damit verbundenen Daten werden gespeichert und verbleiben auf dieser
        Website, bis der kommentierte Inhalt vollst&auml;ndig gel&ouml;scht wurde oder die
        Kommentare aus rechtlichen Gr&uuml;nden gel&ouml;scht werden m&uuml;ssen (z.&nbsp;B.
        beleidigende Kommentare).
      </p>
      <h4>Rechtsgrundlage</h4>{' '}
      <p>
        Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
        a DSGVO). Sie k&ouml;nnen eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu
        reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtm&auml;&szlig;igkeit der bereits
        erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
      </p>
      <h2>4. Soziale Medien</h2>
      <h3>Social-Media-Plugins mit Shariff</h3>{' '}
      <p>
        Auf dieser Website werden Plugins von sozialen Medien verwendet (z.&nbsp;B. Facebook,
        Twitter, Instagram, Pinterest, XING, LinkedIn, Tumblr).
      </p>{' '}
      <p>
        Die Plugins k&ouml;nnen Sie in der Regel anhand der jeweiligen Social-Media-Logos erkennen.
        Um den Datenschutz auf dieser Website zu gew&auml;hrleisten, verwenden wir diese Plugins nur
        zusammen mit der sogenannten &bdquo;Shariff&ldquo;-L&ouml;sung. Diese Anwendung verhindert,
        dass die auf dieser Website integrierten Plugins Daten schon beim ersten Betreten der Seite
        an den jeweiligen Anbieter &uuml;bertragen.
      </p>{' '}
      <p>
        Erst wenn Sie das jeweilige Plugin durch Anklicken der zugeh&ouml;rigen Schaltfl&auml;che
        aktivieren, wird eine direkte Verbindung zum Server des Anbieters hergestellt
        (Einwilligung). Sobald Sie das Plugin aktivieren, erh&auml;lt der jeweilige Anbieter die
        Information, dass Sie mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie
        gleichzeitig in Ihrem jeweiligen Social-Media-Account (z.&nbsp;B. Facebook) eingeloggt sind,
        kann der jeweilige Anbieter den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
      </p>{' '}
      <p>
        Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO
        dar. Diese Einwilligung k&ouml;nnen Sie jederzeit mit Wirkung f&uuml;r die Zukunft
        widerrufen.
      </p>
      <h3>Facebook Plugins (Like &amp; Share-Button)</h3>{' '}
      <p>
        Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert. Anbieter dieses
        Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die
        erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere
        Drittl&auml;nder &uuml;bertragen.
      </p>{' '}
      <p>
        Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem &bdquo;Like-Button&ldquo;
        (&bdquo;Gef&auml;llt mir&ldquo;) auf dieser Website. Eine &Uuml;bersicht &uuml;ber die
        Facebook Plugins finden Sie hier:{' '}
        <a
          href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://developers.facebook.com/docs/plugins/?locale=de_DE
        </a>
        .
      </p>{' '}
      <p>
        Wenn Sie diese Website besuchen, wird &uuml;ber das Plugin eine direkte Verbindung zwischen
        Ihrem Browser und dem Facebook-Server hergestellt. Facebook erh&auml;lt dadurch die
        Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
        Facebook &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in Ihrem Facebook-Account
        eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem Facebook-Profil
        verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir
        weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
        &uuml;bermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen
        hierzu finden Sie in der Datenschutzerkl&auml;rung von Facebook unter:{' '}
        <a
          href="https://de-de.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://de-de.facebook.com/privacy/explanation
        </a>
        .
      </p>{' '}
      <p>
        Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch dieser Website Ihrem
        Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto
        aus.
      </p>{' '}
      <p>
        Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
        die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://www.facebook.com/legal/EU_data_transfer_addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/EU_data_transfer_addendum
        </a>
        ,{' '}
        <a
          href="https://de-de.facebook.com/help/566994660333381"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://de-de.facebook.com/help/566994660333381
        </a>{' '}
        und{' '}
        <a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/policy.php
        </a>
        .
      </p>
      <h3>Twitter Plugin</h3>{' '}
      <p>
        Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden
        angeboten durch die Twitter International Company, One Cumberland Place, Fenian Street,
        Dublin 2, D02 AX07, Irland. Durch das Benutzen von Twitter und der Funktion
        &bdquo;Re-Tweet&ldquo; werden die von Ihnen besuchten Websites mit Ihrem Twitter-Account
        verkn&uuml;pft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
        &uuml;bertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
        Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere
        Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Twitter unter:{' '}
        <a href="https://twitter.com/de/privacy" target="_blank" rel="noopener noreferrer">
          https://twitter.com/de/privacy
        </a>
        .
      </p>{' '}
      <p>
        Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
        die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://gdpr.twitter.com/en/controller-to-controller-transfers.html
        </a>
        .
      </p>{' '}
      <p>
        Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den Konto-Einstellungen unter{' '}
        <a href="https://twitter.com/account/settings" target="_blank" rel="noopener noreferrer">
          https://twitter.com/account/settings
        </a>{' '}
        &auml;ndern.
      </p>
      <h3>Instagram Plugin</h3>{' '}
      <p>
        Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen
        werden angeboten durch die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal
        Harbour, Dublin 2, Irland integriert.
      </p>{' '}
      <p>
        Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie durch Anklicken des
        Instagram-Buttons die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch
        kann Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf
        hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten
        sowie deren Nutzung durch Instagram erhalten.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
        die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://www.facebook.com/legal/EU_data_transfer_addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/EU_data_transfer_addendum
        </a>
        ,{' '}
        <a
          href="https://help.instagram.com/519522125107875"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://help.instagram.com/519522125107875
        </a>{' '}
        und{' '}
        <a
          href="https://de-de.facebook.com/help/566994660333381"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://de-de.facebook.com/help/566994660333381
        </a>
        .
      </p>{' '}
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Instagram:{' '}
        <a
          href="https://instagram.com/about/legal/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://instagram.com/about/legal/privacy/
        </a>
        .
      </p>
      <h3>Tumblr Plugin</h3>{' '}
      <p>
        Diese Website nutzt Schaltfl&auml;chen des Dienstes Tumblr. Anbieter ist die Tumblr, Inc.,
        35 East 21st St, 10th Floor, New York, NY 10010, USA.
      </p>{' '}
      <p>
        Diese Schaltfl&auml;chen erm&ouml;glichen es Ihnen, einen Beitrag oder eine Seite bei Tumblr
        zu teilen oder dem Anbieter bei Tumblr zu folgen. Wenn Sie eine unserer Websites mit
        Tumblr-Button aufrufen, baut der Browser eine direkte Verbindung mit den Servern von Tumblr
        auf. Wir haben keinen Einfluss auf den Umfang der Daten, die Tumblr mit Hilfe dieses Plugins
        erhebt und &uuml;bermittelt. Nach aktuellem Stand werden die IP-Adresse des Nutzers sowie
        die URL der jeweiligen Website &uuml;bermittelt.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
        die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen hierzu finden sich in der Datenschutzerkl&auml;rung von Tumblr unter:{' '}
        <a href="https://www.tumblr.com/privacy/de" target="_blank" rel="noopener noreferrer">
          https://www.tumblr.com/privacy/de
        </a>
        .
      </p>
      <h3>Pinterest Plugin</h3>{' '}
      <p>
        Auf dieser Website verwenden wir Social Plugins des sozialen Netzwerkes Pinterest, das von
        der Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland
        betrieben wird.
      </p>{' '}
      <p>
        Wenn Sie eine Seite aufrufen, die ein solches Plugin enth&auml;lt, stellt Ihr Browser eine
        direkte Verbindung zu den Servern von Pinterest her. Das Plugin &uuml;bermittelt dabei
        Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten enthalten
        m&ouml;glicherweise Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls
        Pinterest-Funktionen enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der
        Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
        die Einwilligung ist jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch
        Pinterest sowie Ihre diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz Ihrer
        Privatsph&auml;re finden Sie in den Datenschutzhinweisen von Pinterest:{' '}
        <a
          href="https://policy.pinterest.com/de/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policy.pinterest.com/de/privacy-policy
        </a>
        .
      </p>
      <h2>5. Analyse-Tools und Werbung</h2>
      <h3>Matomo (ehemals Piwik)</h3>{' '}
      <p>
        Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet
        Technologien, die die seiten&uuml;bergreifende Wiedererkennung des Nutzers zur Analyse des
        Nutzerverhaltens erm&ouml;glichen (z.B. Cookies oder Device-Fingerprinting). Die durch
        Matomo erfassten Informationen &uuml;ber die Benutzung dieser Website werden auf unserem
        Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.
      </p>{' '}
      <p>
        Mit Hilfe von Matomo sind wir in der Lage Daten &uuml;ber die Nutzung unserer Website durch
        die Websitebesucher zu erfassen und zu analysieren. Hierdurch k&ouml;nnen wir u. a.
        herausfinden, wann welche Seitenaufrufe get&auml;tigt wurden und aus welcher Region sie
        kommen. Au&szlig;erdem erfassen wir verschiedene Logdateien (z.B. IP-Adresse, Referrer,
        verwendete Browser und Betriebssysteme) und k&ouml;nnen messen, ob unsere Websitebesucher
        bestimmte Aktionen durchf&uuml;hren (z.B. Klicks, K&auml;ufe u. &Auml;.).
      </p>{' '}
      <p>
        Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern
        eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
        Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
        a DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <h4>Hosting</h4>{' '}
      <p>
        Wir hosten Matomo ausschlie&szlig;lich auf unseren eigenen Servern, sodass alle Analysedaten
        bei uns verbleiben und nicht weitergegeben werden.
      </p>
      <h3>IONOS WebAnalytics</h3>{' '}
      <p>
        Diese Website nutzt die Analysedienste von IONOS WebAnalytics (im Folgenden: IONOS).
        Anbieter ist die 1&amp;1 IONOS SE, Elgendorfer Stra&szlig;e 57, D &ndash; 56410 Montabaur.
        Im Rahmen der Analysen mit IONOS k&ouml;nnen u. a. Besucherzahlen und &ndash;verhalten
        (z.&nbsp;B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs, Absprungraten),
        Besucherquellen (d. h., von welcher Seite der Besucher kommt), Besucherstandorte sowie
        technische Daten (Browser- und Betriebssystemversionen) analysiert werden. Zu diesem Zweck
        speichert IONOS insbesondere folgende Daten:
      </p>{' '}
      <ul>
        {' '}
        <li>Referrer (zuvor besuchte Webseite)</li> <li>angeforderte Webseite oder Datei</li>{' '}
        <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>{' '}
        <li>verwendeter Ger&auml;tetyp</li> <li>Uhrzeit des Zugriffs</li>{' '}
        <li>
          IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs
          verwendet)
        </li>{' '}
      </ul>{' '}
      <p>
        Die Datenerfassung erfolgt laut IONOS vollst&auml;ndig anonymisiert, sodass sie nicht zu
        einzelnen Personen zur&uuml;ckverfolgt werden kann. Cookies werden von IONOS WebAnalytics
        nicht gespeichert.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an der statistischen Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen zur Datenerfassung und Verarbeitung durch IONOS WebAnalytics entnehmen
        Sie der Datenschutzerklaerung von IONOS unter folgendem Link:
      </p>{' '}
      <p>
        <a
          href="https://www.ionos.de/terms-gtc/index.php?id=6"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.ionos.de/terms-gtc/index.php?id=6
        </a>
      </p>
      <h4>Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben mit IONOS einen Vertrag zur Auftragsverarbeitung abgeschlossen. Dieser Vertrag
        soll den datenschutzkonformen Umgang mit Ihren personenbezogenen Daten durch IONOS
        sicherstellen.
      </p>
      <h3>WordPress Statistik</h3>{' '}
      <p>
        Diese Website nutzt &bdquo;WordPress Statistik&ldquo;, um Besucherzugriffe statistisch
        auszuwerten. Anbieter ist die Automattic Inc., 60 29th Street #343, San Francisco, CA
        94110-4929, USA.
      </p>{' '}
      <p>
        WordPress Statistik verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke
        der Analyse des Nutzerverhaltens erm&ouml;glichen (z.B. Cookies oder Device-Fingerprinting).
        WordPress Statistik erfasst zur Analyse u. a. Logdateien (Referrer, IP-Adresse, Browser u.
        a.), die Herkunft der Websitebesucher (Land, Stadt) und welche Aktionen sie auf der Seite
        get&auml;tigt haben (z.B. Klicks, Ansichten, Downloads). Die so erfassten Informationen
        &uuml;ber die Benutzung dieser Website werden auf Servern in den USA gespeichert. Ihre
        IP-Adresse wird nach der Verarbeitung und vor der Speicherung anonymisiert.
      </p>{' '}
      <p>
        Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern
        eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
        Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
        a DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <h2>6. Newsletter</h2>
      <h3>Newsletter&shy;daten</h3>{' '}
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen
        wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung
        gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
        Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis
        erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r den Versand der
        angeforderten Informationen und geben diese nicht an Dritte weiter.
      </p>{' '}
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
        ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die
        erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
        Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa &uuml;ber den
        &bdquo;Austragen&ldquo;-Link im Newsletter. Die Rechtm&auml;&szlig;igkeit der bereits
        erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
      </p>{' '}
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
        gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der
        Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem
        Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach
        Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.
      </p>{' '}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw.
        dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k&uuml;nftige Mailings
        zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und
        nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch
        unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
        (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
        Blacklist ist zeitlich nicht befristet.{' '}
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
          Interesse &uuml;berwiegen.
        </strong>
      </p>
      <h3>Zoho Campaigns</h3>{' '}
      <p>
        Diese Website nutzt Zoho Campaigns f&uuml;r den Versand von Newslettern. Anbieter ist Zoho
        Corporation Pvt. Ltd., Estancia IT Park, Plot No. 140 151, GST Road, Vallancherry Village,
        Chengalpattu Taluk, Kanchipuram District 603 202, Indien (nachfolgend &bdquo;Zoho
        Campaigns&ldquo;).
      </p>{' '}
      <p>
        Zoho Campaigns ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und
        analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten
        werden auf den Servern von Zoho Campaigns gespeichert.
      </p>{' '}
      <h4>Datenanalyse durch Zoho Campaigns</h4>{' '}
      <p>
        Mit Hilfe von Zoho Campaigns ist es uns m&ouml;glich, unsere Newsletter-Kampagnen zu
        analysieren. So k&ouml;nnen wir z.B. sehen, ob eine Newsletter-Nachricht ge&ouml;ffnet wurde
        und welche Links ggf. angeklickt wurden. Auf diese Weise k&ouml;nnen wir u.a. feststellen,
        welche Links besonders oft angeklickt wurden.
      </p>{' '}
      <p>
        Au&szlig;erdem k&ouml;nnen wir erkennen, ob nach dem &Ouml;ffnen / Anklicken bestimmte
        vorher definierte Aktionen durchgef&uuml;hrt wurden (Conversion-Rate). Wir k&ouml;nnen so
        z.B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf get&auml;tigt haben.
        Wenn Sie keine Analyse durch Zoho Campaigns wollen, m&uuml;ssen Sie den Newsletter
        abbestellen. Hierf&uuml;r stellen wir in jeder Newsletternachricht einen entsprechenden Link
        zur Verf&uuml;gung. Zoho Campaigns erm&ouml;glicht es uns auch, die
        Newsletter-Empf&auml;nger anhand verschiedener Kategorien zu unterteilen
        (&bdquo;clustern&rdquo;). Dabei lassen sich die Newsletterempf&auml;nger z.B. nach Alter,
        Geschlecht oder Wohnort unterteilen. Auf diese Weise lassen sich die Newsletter besser an
        die jeweiligen Zielgruppen anpassen. Wenn Sie keine Analyse durch Zoho Campaigns wollen,
        m&uuml;ssen Sie den Newsletter abbestellen. Hierf&uuml;r stellen wir in jeder
        Newsletternachricht einen entsprechenden Link zur Verf&uuml;gung.
      </p>{' '}
      <p>
        Ausf&uuml;hrliche Informationen zu den Funktionen von Zoho Campaigns entnehmen Sie folgendem
        Link:{' '}
        <a
          href="https://www.zoho.com/campaigns/features.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zoho.com/campaigns/features.html
        </a>
        .
      </p>{' '}
      <p>
        Die Datenschutzerkl&auml;rung von Zoho Campaigns finden Sie unter:{' '}
        <a href="https://www.zoho.com/privacy.html" target="_blank" rel="noopener noreferrer">
          https://www.zoho.com/privacy.html
        </a>{' '}
        und{' '}
        <a href="https://www.zoho.com/gdpr.html" target="_blank" rel="noopener noreferrer">
          https://www.zoho.com/gdpr.html
        </a>
        .
      </p>{' '}
      <h4>Rechtsgrundlage</h4>{' '}
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        Sie k&ouml;nnen diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://www.zoho.com/privacy/dpa/DPA_zoho_com.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zoho.com/privacy/dpa/DPA_zoho_com.pdf
        </a>
        .
      </p>{' '}
      <h4>Speicherdauer</h4>{' '}
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
        gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste oder
        nach Zweckfortfall gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem
        Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach
        Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren. Daten, die zu anderen Zwecken
        bei uns gespeichert wurden, bleiben hiervon unber&uuml;hrt.
      </p>{' '}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw.
        dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k&uuml;nftige Mailings
        zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und
        nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch
        unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
        (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
        Blacklist ist zeitlich nicht befristet.{' '}
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
          Interesse &uuml;berwiegen.
        </strong>
      </p>
      <h4>Vertrag &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit Zoho Campaigns geschlossen.
        Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass Zoho Campaigns die personenbezogenen Daten unserer Websitebesucher
        nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>ConvertKit</h3>{' '}
      <p>
        Diese Website nutzt ConvertKit f&uuml;r den Versand von Newslettern. Anbieter ist die
        ConvertKit LLC, North Carolina, USA (nachfolgend &bdquo;ConvertKit&ldquo;).
      </p>{' '}
      <p>
        ConvertKit ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und
        analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten
        werden auf den Servern von ConvertKit gespeichert.
      </p>{' '}
      <h4>Datenanalyse durch ConvertKit</h4>{' '}
      <p>
        Mit Hilfe von ConvertKit ist es uns m&ouml;glich, unsere Newsletter-Kampagnen zu
        analysieren. So k&ouml;nnen wir z.B. sehen, ob eine Newsletter-Nachricht ge&ouml;ffnet wurde
        und welche Links ggf. angeklickt wurden. Auf diese Weise k&ouml;nnen wir u.a. feststellen,
        welche Links besonders oft angeklickt wurden.
      </p>{' '}
      <p>
        Au&szlig;erdem k&ouml;nnen wir erkennen, ob nach dem &Ouml;ffnen / Anklicken bestimmte
        vorher definierte Aktionen durchgef&uuml;hrt wurden (Conversion-Rate). Wir k&ouml;nnen so
        z.B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf get&auml;tigt haben.
      </p>{' '}
      <p>
        ConvertKit erm&ouml;glicht es uns auch, die Newsletter-Empf&auml;nger anhand verschiedener
        Kategorien zu unterteilen (&bdquo;clustern&rdquo;). Dabei lassen sich die
        Newsletterempf&auml;nger z.B. nach Alter, Geschlecht oder Wohnort unterteilen. Auf diese
        Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. Wenn Sie
        keine Analyse durch ConvertKit wollen, m&uuml;ssen Sie den Newsletter abbestellen.
        Hierf&uuml;r stellen wir in jeder Newsletternachricht einen entsprechenden Link zur
        Verf&uuml;gung.
      </p>{' '}
      <p>
        Ausf&uuml;hrliche Informationen zu den Funktionen von ConvertKit entnehmen Sie folgendem
        Link:{' '}
        <a
          href="https://convertkit.com/features/email-marketing"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://convertkit.com/features/email-marketing
        </a>
        .
      </p>{' '}
      <p>
        Die Datenschutzerkl&auml;rung von ConvertKit finden Sie unter:{' '}
        <a href="https://convertkit.com/privacy" target="_blank" rel="noopener noreferrer">
          https://convertkit.com/privacy
        </a>
        .
      </p>{' '}
      <h4>Rechtsgrundlage</h4>{' '}
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        Sie k&ouml;nnen diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a href="https://convertkit.com/security" target="_blank" rel="noopener noreferrer">
          https://convertkit.com/security
        </a>
        .
      </p>{' '}
      <h4>Speicherdauer</h4>{' '}
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
        gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste oder
        nach Zweckfortfall gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem
        Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach
        Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren. Daten, die zu anderen Zwecken
        bei uns gespeichert wurden, bleiben hiervon unber&uuml;hrt.
      </p>{' '}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw.
        dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k&uuml;nftige Mailings
        zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und
        nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch
        unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
        (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
        Blacklist ist zeitlich nicht befristet.{' '}
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
          Interesse &uuml;berwiegen.
        </strong>
      </p>
      <h4>Vertrag &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit ConvertKit geschlossen. Hierbei
        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass ConvertKit die personenbezogenen Daten unserer Websitebesucher nur
        nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>MailPoet</h3>{' '}
      <p>
        Diese Website nutzt MailPoet f&uuml;r den Versand von Newslettern. Anbieter ist die{' '}
        <em>Wysija SARL,</em> 6 rue Dieud&eacute;, 13006, Marseille, Frankreich (nachfolgend
        MailPoet).
      </p>{' '}
      <p>
        MailPoet ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert
        werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf
        unseren Servern gespeichert aber &uuml;ber die Server von MailPoet versandt, sodass MailPoet
        Ihre newsletterbezogenen Daten verarbeitet (MailPoet Sending Service). Details finden Sie
        hier:{' '}
        <a href="https://account.mailpoet.com/" target="_blank" rel="noopener noreferrer">
          https://account.mailpoet.com/
        </a>
        .
      </p>{' '}
      <h4>Datenanalyse durch MailPoet</h4>{' '}
      <p>
        Mit Hilfe von MailPoet ist es uns m&ouml;glich, unsere Newsletter-Kampagnen zu analysieren.
        So k&ouml;nnen wir z.B. sehen, ob eine Newsletter-Nachricht ge&ouml;ffnet wurde und welche
        Links ggf. angeklickt wurden. Auf diese Weise k&ouml;nnen wir u.a. feststellen, welche Links
        besonders oft angeklickt wurden.
      </p>{' '}
      <p>
        Au&szlig;erdem k&ouml;nnen wir erkennen, ob nach dem &Ouml;ffnen / Anklicken bestimmte
        vorher definierte Aktionen durchgef&uuml;hrt wurden (Conversion-Rate). Wir k&ouml;nnen so
        z.B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf get&auml;tigt haben.
      </p>{' '}
      <p>
        MailPoet erm&ouml;glicht es uns auch, die Newsletter-Empf&auml;nger anhand verschiedener
        Kategorien zu unterteilen (&bdquo;clustern&rdquo;). Dabei lassen sich die
        Newsletterempf&auml;nger z.B. nach Alter, Geschlecht oder Wohnort unterteilen. Auf diese
        Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. Wenn Sie
        keine Analyse durch MailPoet wollen, m&uuml;ssen Sie den Newsletter abbestellen.
        Hierf&uuml;r stellen wir in jeder Newsletternachricht einen entsprechenden Link zur
        Verf&uuml;gung.
      </p>{' '}
      <p>
        Ausf&uuml;hrliche Informationen zu den Funktionen von MailPoet entnehmen Sie folgendem Link:{' '}
        <a href="https://account.mailpoet.com/" target="_blank" rel="noopener noreferrer">
          https://account.mailpoet.com/
        </a>{' '}
        und{' '}
        <a
          href="https://www.mailpoet.com/mailpoet-features/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.mailpoet.com/mailpoet-features/
        </a>
        .
      </p>{' '}
      <p>
        Die Datenschutzerkl&auml;rung von MailPoet finden Sie unter:{' '}
        <a
          href="https://www.mailpoet.com/privacy-notice/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.mailpoet.com/privacy-notice/
        </a>
        .
      </p>{' '}
      <h4>Rechtsgrundlage</h4>{' '}
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        Sie k&ouml;nnen diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen.
      </p>{' '}
      <h4>Speicherdauer</h4>{' '}
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter bei uns gespeichert und nach der Abbestellung des
        Newsletters aus der Newsletterverteilerliste oder nach Zweckfortfall gel&ouml;scht. Wir
        behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im
        Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder
        zu sperren. Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon
        unber&uuml;hrt.
      </p>{' '}
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns ggf.
        in einer Blacklist gespeichert, um k&uuml;nftige Mailings zu verhindern. Die Daten aus der
        Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten
        zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
        Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im
        Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
        befristet.{' '}
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
          Interesse &uuml;berwiegen.
        </strong>
      </p>
      <h4>Vertrag &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit MailPoet geschlossen. Hierbei
        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass MailPoet die personenbezogenen Daten unserer Websitebesucher nur
        nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h2>7. Plugins und Tools</h2>
      <h3>YouTube mit erweitertem Datenschutz</h3>{' '}
      <p>
        Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland
        Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>{' '}
      <p>
        Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass
        YouTube keine Informationen &uuml;ber die Besucher auf dieser Website speichert, bevor diese
        sich das Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
        erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt YouTube
        &ndash; unabh&auml;ngig davon, ob Sie sich ein Video ansehen &ndash; eine Verbindung zum
        Google DoubleClick-Netzwerk her.
      </p>{' '}
      <p>
        Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern
        von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
        besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen Sie
        YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil zuzuordnen. Dies
        k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
      </p>{' '}
      <p>
        Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem
        Endger&auml;t speichern oder vergleichbare Wiedererkennungstechnologien (z.B.
        Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube Informationen &uuml;ber
        Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um
        Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
        vorzubeugen.
      </p>{' '}
      <p>
        Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos weitere
        Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden, auf die wir keinen Einfluss haben.
      </p>{' '}
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
        DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen &uuml;ber Datenschutz bei YouTube finden Sie in deren
        Datenschutzerkl&auml;rung unter:{' '}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>Vimeo ohne Tracking (Do-Not-Track)</h3>{' '}
      <p>
        Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West
        18th Street, New York, New York 10011, USA.
      </p>{' '}
      <p>
        Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten besuchen, wird eine Verbindung
        zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer
        Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Wir haben Vimeo jedoch so
        eingestellt, dass Vimeo Ihre Nutzeraktivit&auml;ten nicht nachverfolgen und keine Cookies
        setzen wird.
      </p>{' '}
      <p>
        Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f
        DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        sowie nach Aussage von Vimeo auf &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo;
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">
          https://vimeo.com/privacy
        </a>
        .
      </p>{' '}
      <p>
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung
        von Vimeo unter:{' '}
        <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">
          https://vimeo.com/privacy
        </a>
        .
      </p>
      <h3>Google Web Fonts (lokales Hosting)</h3>{' '}
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die
        von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung
        zu Servern von Google findet dabei nicht statt.
      </p>{' '}
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
        <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">
          https://developers.google.com/fonts/faq
        </a>{' '}
        und in der Datenschutzerkl&auml;rung von Google:{' '}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>Font Awesome (lokales Hosting)</h3>{' '}
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome. Font Awesome
        ist lokal installiert. Eine Verbindung zu Servern von Fonticons, Inc.&nbsp; findet dabei
        nicht statt.
      </p>{' '}
      <p>
        Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der Datenschutzerkl&auml;rung
        f&uuml;r Font Awesome unter:{' '}
        <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer">
          https://fontawesome.com/privacy
        </a>
        .
      </p>
      <h3>Google Maps</h3>{' '}
      <p>
        Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited
        (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>{' '}
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern.
        Diese Informationen werden in der Regel an einen Server von Google in den USA
        &uuml;bertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
        diese Daten&uuml;bertragung.
      </p>{' '}
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen
        Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier:{' '}
        <a
          href="https://privacy.google.com/businesses/gdprcontrollerterms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.google.com/businesses/gdprcontrollerterms/
        </a>{' '}
        und{' '}
        <a
          href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
        </a>
        .
      </p>{' '}
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung
        von Google:{' '}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>OpenStreetMap</h3>{' '}
      <p>
        Wir nutzen den Kartendienst von OpenStreetMap (OSM). Anbieterin ist die Open-Street-Map
        Foundation (OSMF), 132 Maney Hill Road, Sutton Coldfield, West Midlands, B72 1JU, United
        Kingdom.
      </p>{' '}
      <p>
        Wenn Sie eine Website besuchen, auf der OpenStreetMap eingebunden ist, werden u. a. Ihre
        IP-Adresse und weitere Informationen &uuml;ber Ihr Verhalten auf dieser Website an die OSMF
        weitergeleitet. OpenStreetMap speichert hierzu unter Umst&auml;nden Cookies in Ihrem Browser
        oder setzt vergleichbare Wiedererkennungstechnologien ein.
      </p>{' '}
      <p>
        Ferner kann Ihr Standort erfasst werden, wenn Sie dies in Ihren Ger&auml;teeinstellungen
        &ndash; z.&nbsp;B. auf Ihrem Handy &ndash; zugelassen haben. Der Anbieter dieser Seite hat
        keinen Einfluss auf diese Daten&uuml;bertragung. Details entnehmen Sie der
        Datenschutzerkl&auml;rung von OpenStreetMap unter folgendem Link:{' '}
        <a
          href="https://wiki.osmfoundation.org/wiki/Privacy_Policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://wiki.osmfoundation.org/wiki/Privacy_Policy
        </a>
        .
      </p>{' '}
      <p>
        Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote und einer leichten Auffindbarkeit der von uns auf der Website angegebenen
        Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
        Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
        Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <h3>Google reCAPTCHA</h3>{' '}
      <p>
        Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden &bdquo;reCAPTCHA&ldquo;) auf dieser
        Website. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
        Barrow Street, Dublin 4, Irland.
      </p>{' '}
      <p>
        Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf dieser Website
        (z.&nbsp;B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes
        Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
        verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die
        Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.&nbsp;B.
        IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte
        Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.
      </p>{' '}
      <p>
        Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden nicht
        darauf hingewiesen, dass eine Analyse stattfindet.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
        missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen
        und den Google Nutzungsbedingungen unter folgenden Links:{' '}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>{' '}
        und{' '}
        <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">
          https://policies.google.com/terms?hl=de
        </a>
        .
      </p>
      <h3>SoundCloud</h3>{' '}
      <p>
        Auf dieser Website k&ouml;nnen Plugins des sozialen Netzwerks SoundCloud (SoundCloud
        Limited, Berners House, 47-48 Berners Street, London W1T 3NF, Gro&szlig;britannien.)
        integriert sein. Die SoundCloud-Plugins erkennen Sie an dem SoundCloud-Logo auf den
        betroffenen Seiten.
      </p>{' '}
      <p>
        Wenn Sie diese Website besuchen, wird nach Aktivierung des Plugins eine direkte Verbindung
        zwischen Ihrem Browser und dem SoundCloud-Server hergestellt. SoundCloud erh&auml;lt dadurch
        die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
        &bdquo;Like-Button&ldquo; oder &bdquo;Share-Button&ldquo; anklicken, w&auml;hrend Sie in
        Ihrem SoundCloud- Benutzerkonto eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website
        mit Ihrem SoundCloud-Profil verlinken und/oder teilen. Dadurch kann SoundCloud Ihrem
        Benutzerkonto den Besuch dieser Website zuordnen. Wir weisen darauf hin, dass wir als
        Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren
        Nutzung durch SoundCloud erhalten.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen
        Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde
        (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von SoundCloud
        unter:{' '}
        <a href="https://soundcloud.com/pages/privacy" target="_blank" rel="noopener noreferrer">
          https://soundcloud.com/pages/privacy
        </a>
        .
      </p>{' '}
      <p>
        Wenn Sie nicht w&uuml;nschen, dass SoundCloud den Besuch dieser Website Ihrem SoundCloud-
        Benutzerkonto zuordnet, loggen Sie sich bitte aus Ihrem SoundCloud-Benutzerkonto aus bevor
        Sie Inhalte des SoundCloud-Plugins aktivieren.
      </p>
      <h3>Spotify</h3>{' '}
      <p>
        Auf dieser Website sind Funktionen des Musik-Dienstes Spotify eingebunden. Anbieter ist die
        Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm in Schweden. Die Spotify Plugins erkennen
        Sie an dem gr&uuml;nen Logo auf dieser Website. Eine &Uuml;bersicht &uuml;ber die
        Spotify-Plugins finden Sie unter:{' '}
        <a href="https://developer.spotify.com" target="_blank" rel="noopener noreferrer">
          https://developer.spotify.com
        </a>
        .
      </p>{' '}
      <p>
        Dadurch kann beim Besuch dieser Website &uuml;ber das Plugin eine direkte Verbindung
        zwischen Ihrem Browser und dem Spotify-Server hergestellt werden. Spotify erh&auml;lt
        dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie
        den Spotify Button anklicken, w&auml;hrend Sie in Ihrem Spotify-Account eingeloggt sind,
        k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem Spotify Profil verlinken. Dadurch kann
        Spotify den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
      </p>{' '}
      <p>
        Wir weisen darauf hin, dass bei der Nutzung von Spotify Cookies von Google Analytics
        eingesetzt werden, sodass Ihre Nutzungsdaten bei der Nutzung von Spotify auch an Google
        weitergegeben werden k&ouml;nnen. Google Analytics ist ein Tool des Google-Konzerns zur
        Analyse des Nutzerverhaltens mit Sitz in den USA. F&uuml;r diese Einbindung ist allein
        Spotify verantwortlich. Wir als Websitebetreiber haben auf diese Verarbeitung keinen
        Einfluss.
      </p>{' '}
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an der ansprechenden akustischen
        Ausgestaltung seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
        eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
        jederzeit widerrufbar.
      </p>{' '}
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Spotify:{' '}
        <a
          href="https://www.spotify.com/de/legal/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.spotify.com/de/legal/privacy-policy/
        </a>
        .
      </p>{' '}
      <p>
        Wenn Sie nicht w&uuml;nschen, dass Spotify den Besuch dieser Website Ihrem
        Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Spotify-Benutzerkonto
        aus.
      </p>
      <h2>8. Eigene Dienste</h2>
      <h3>OneDrive</h3>{' '}
      <p>
        Wir haben OneDrive auf dieser Website eingebunden. Anbieter ist die Microsoft Corporation,
        One Microsoft Way, Redmond, WA 98052-6399, USA (im folgenden &bdquo;OneDrive&ldquo;).
      </p>{' '}
      <p>
        OneDrive erm&ouml;glicht es uns, einen Uploadbereich auf unserer Website einzubinden, in dem
        Sie Inhalte hochladen k&ouml;nnen. Wenn Sie Inhalte hochladen, werden diese auf den Servern
        von OneDrive gespeichert. Wenn Sie unsere Website betreten wird au&szlig;erdem eine
        Verbindung zu OneDrive aufgebaut, sodass OneDrive feststellen kann, dass Sie unsere Website
        besucht haben.
      </p>{' '}
      <p>
        Die Verwendung von OneDrive erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an einem zuverl&auml;ssigen Uploadbereich
        auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <h4>Abschluss eines Vertrags &uuml;ber Auftragsverarbeitung</h4>{' '}
      <p>
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung mit OneDrive geschlossen. Hierbei
        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
        gew&auml;hrleistet, dass OneDrive die personenbezogenen Daten unserer Websitebesucher nur
        nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
    </Text>
  </Box>
);

export default Datenschutz;
