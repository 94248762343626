import { Box, Text } from 'grommet';
import React from 'react';

const Widerruf = () => (
  <Box margin="auto" width={{ max: 'large' }}>
    <Text wordBreak="break-word">
      <h1>Widerruf</h1>
      <b>
        Widerrufsbelehrung / Widerrufsrecht <br />
        <br />
      </b>
      <p>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
        widerrufen.
        <br />
        <br />
        Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag, an dem Sie oder ein von Ihnen
        benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw.
        hat.
        <br />
        <br />
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
        <br />
        Kilian Kunkel
        <br />
        Kilian Kunkel
        <br />
        Am Winacker 10
        <br />
        D-83646 Bad Tölz
        <br />
        E-Mail kiliankunkel@gmx.de
        <br />
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder
        E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür
        das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        <br />
        <br />
        <b>Widerrufsfolgen</b>
        <br />
        <br />
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
        erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
        sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
        günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn
        Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
        bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
        Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
        ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
        Entgelte berechnet.
        <br />
        <br />
        Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder
        bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem,
        welches der frühere Zeitpunkt ist.
        <br />
        <br />
        Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem
        Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an uns zurückzusenden
        oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von
        vierzehn Tagen absenden.
        <br />
        <br />
        Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
        <br />
        <br />
        <b>Ende der Widerrufsbelehrung</b>
        <br />
        <br />
      </p>
      <p>§7 Widerrufsformular</p>
      <p>Muster-Widerrufsformular</p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden
      Sie es zurück.)
      <br />
      <div>
        An :<br />
        Kilian Kunkel
        <br />
        Kilian Kunkel
        <br />
        Am Winacker 10
        <br />
        D-83646 Bad Tölz
        <br />
        E-Mail kiliankunkel@gmx.de
        <br />
        <br />
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf
        der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
        <br />
        <br />
        __________________________
        <br />
        <br />
        Bestellt am (*)/erhalten am (*)
        <br />
        <br />
        __________________________
        <br />
        <br />
        Name des/der Verbraucher(s)
        <br />
        <br />
        __________________________
        <br />
        <br />
        Anschrift des/der Verbraucher(s)
        <br />
        <br />
        <br />
        __________________________
        <br />
        <br />
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br />
        <br />
        __________________________
        <br />
        <br />
        Datum
        <br />
        <br />
        __________________________
        <br />
        <br />
      </div>
      (*) Unzutreffendes streichen.
      <br />
      <br />
    </Text>
  </Box>
);
export default Widerruf;
