import { Box, Button, Form, FormField, Heading, Text, TextInput } from 'grommet';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import Cookies from 'universal-cookie';

interface RegisterProps {}

const Register: React.FunctionComponent<RegisterProps & RouteComponentProps> = (
  props: RegisterProps & RouteComponentProps
) => {
  const [failed, setFailed] = useState(false);
  const [notAll, setNotAll] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [secondsToRedirect, setSecondsToRedirect] = useState(3);
  const [credentials, setCredentials] = useState({
    email: '',
    username: '',
    password: '',
    passwordrepeat: '',
  });
  const history = useHistory();
  async function login() {
    if (
      !credentials.email ||
      !credentials.password ||
      !credentials.passwordrepeat ||
      !credentials.username
    ) {
      setNotAll(true);
    }
    if (credentials.password !== credentials.passwordrepeat) {
      setFailed(true);
    } else {
      const user = {
        email: credentials.email,
        username: credentials.username,
        password: credentials.password,
      };
      await fetch('https://api.kiliankunkel.de/registration', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.access_token) {
            const cookies = new Cookies();
            cookies.set('credentials', result);
            setSubmitted(true);
          }
        });
    }
  }

  if (submitted) {
    if (secondsToRedirect === 0) {
      if (history.location.pathname === '/login' || history.location.pathname === '/register') {
        props.history.push('admin');
      } else {
        history.go(0);
      }
    }
    setTimeout(() => {
      setSecondsToRedirect(secondsToRedirect - 1);
    }, 1000);
  }
  return (
    <Box margin="auto" width={{ max: 'large' }}>
      {!submitted && (
        <>
          <Heading textAlign="center">register</Heading>
          <Box margin="auto">
            <Form
              onSubmit={() => {
                login();
              }}
              value={credentials}
              onChange={(nextValue) =>
                setCredentials(
                  nextValue as {
                    email: string;
                    username: string;
                    password: string;
                    passwordrepeat: string;
                  }
                )
              }
            >
              <FormField label="email">
                <TextInput id="text-input-id" name="email" autoComplete="email" type="email" />
              </FormField>
              <FormField label="username">
                <TextInput id="text-input-id" name="username" autoComplete="username" />
              </FormField>
              <FormField label="password">
                <TextInput
                  id="text-input-id"
                  name="password"
                  autoComplete="current-password"
                  type="password"
                />
              </FormField>
              <FormField label="repeat password">
                <TextInput
                  id="text-input-id"
                  name="passwordrepeat"
                  autoComplete="current-password"
                  type="password"
                />
              </FormField>
              <Box direction="row" gap="medium" justify="center">
                <Button type="submit" label="register" />
              </Box>
            </Form>
          </Box>
          <Box margin={{ top: 'medium' }}>
            {failed && (
              <Text color="red" textAlign="center">
                The passwords are not equal or this username does already exist.
              </Text>
            )}
            {notAll && (
              <Text color="red" textAlign="center">
                Please fill in all fields.
              </Text>
            )}
          </Box>
          <Box direction="column" margin="large" justify="center">
            <Text alignSelf="center">or:</Text>
            <Button
              margin="auto"
              size="small"
              label="login"
              onClick={() => history.push('/login')}
            />
          </Box>
        </>
      )}
      {submitted && (
        <Box>
          <Heading className="contentAnimation" textAlign="center">
            sucessfully registered
          </Heading>
          <Text alignSelf="center">redirect to login in:</Text>
          <Text alignSelf="center">{secondsToRedirect}</Text>
        </Box>
      )}
    </Box>
  );
};
export default withRouter(Register);
