import React, { useRef, useState } from 'react';
import { Anchor, Box, Button, Grid, Header, Image, ResponsiveContext } from 'grommet';
import 'images/kilian.css';
import logo from 'images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Links from './Links';
import 'transitions/transitions.css';

const TopBar = withRouter(({ history }) => {
  // ab769px: medium
  const size = React.useContext(ResponsiveContext);
  const [showLayer, setShowLayer] = useState(false);
  const nodeRef = useRef(null);
  return (
    <>
      <Header background="light-2" flex={false} tag="header">
        <Grid
          fill={true}
          rows={['xsmall', '100%']}
          columns={['30%', '40%', '30%']}
          areas={[
            { name: 'menu', start: [1, 1], end: [1, 1] },
            { name: 'left', start: [0, 0], end: [0, 0] },
            { name: 'middle', start: [1, 0], end: [1, 0] },
            { name: 'right', start: [2, 0], end: [2, 0] },
          ]}
        >
          <Box gridArea="left">
            <Box pad="small" direction="row" fill>
              {size === 'large' && (
                <Box width="xsmall">
                  <Image fit="contain" fill src={logo} />
                </Box>
              )}
            </Box>
          </Box>
          <Box gridArea="middle" align="center" justify="center">
            <Anchor label="kiliankunkel.de" color="dark-2" onClick={() => history.push('/')} />
          </Box>
          <Box gridArea="right" pad="small" gap="large" direction="row-reverse">
            {(size === 'medium' || size === 'large') && <Links setShowLayer={setShowLayer} />}
            {(size === 'small' || size === 'xsmall') && (
              <Box direction="row" gap="small">
                {showLayer && (
                  <Button
                    icon={<FontAwesomeIcon icon={faTimes} />}
                    onClick={() => {
                      setShowLayer(!showLayer);
                    }}
                    size="small"
                    alignSelf="center"
                  />
                )}
                {!showLayer && (
                  <Button
                    icon={<FontAwesomeIcon icon={faBars} />}
                    onClick={() => {
                      setShowLayer(!showLayer);
                    }}
                    size="small"
                    alignSelf="center"
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Header>
      <Box>
        {(size === 'small' || size === 'xsmall') && (
          <CSSTransition
            in={showLayer}
            timeout={300}
            classNames="alert"
            nodeRef={nodeRef}
            unmountOnExit
            onEnter={() => setShowLayer(true)}
            onExited={() => setShowLayer(false)}
          >
            <Box background="light-2">
              <Links setShowLayer={setShowLayer} />
            </Box>
          </CSSTransition>
        )}
      </Box>
    </>
  );
});

export default TopBar;
