import { Box, Grommet } from 'grommet';
import React from 'react';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import TopBar from 'components/TopBar';
import BottomBar from 'components/BottomBar';
import theme from 'components/theme';
import RoutRenderer from 'components/RoutRenderer';

// ${(props) => props.theme.global.colors['dark-5']}
// change to proper routing

function App() {
  return (
    <Grommet theme={theme} className="page">
      <BrowserRouter>
        <Router>
          <Box width={{ min: '360px' }} className="header" overflow="hidden">
            <TopBar />
          </Box>
          <Box width={{ min: '360px' }} className="content" flex="grow">
            <Box flex="grow" pad={{ top: '100px', horizontal: '20px' }}>
              <RoutRenderer />
            </Box>
            <Box>
              <BottomBar />
            </Box>
          </Box>
        </Router>
      </BrowserRouter>
    </Grommet>
  );
}

export default App;
