import React, { useState } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import play from 'images/thumbnails/play.png';
import mergeImages from 'merge-images';

interface VideoComponentProps {
  src: string;
  srcSite: string;
  title: string;
}
// https://img.youtube.com/vi/<insert-youtube-video-id-here>/maxresdefault.jpg

const VideoComponent: React.FC<VideoComponentProps> = ({ src, srcSite, title }) => {
  const size = React.useContext(ResponsiveContext);
  let height = '360';
  let width = '640';
  if (size === 'small' || size === 'xsmall') {
    height = '144';
    width = '256';
  }

  const [blockVideo, setBlockVideo] = useState(true);

  const [images, setImages] = useState(src);
  mergeImages([{ src }, { src: play, x: 360, y: 125, opacity: 0.7 }]).then((result) =>
    setImages(result)
  );
  return (
    <Box className="center" animation="fadeIn">
      <Box
        border={{
          color: 'dark-2',
          size: 'medium',
          style: 'solid',
          side: 'bottom',
        }}
        pad="small"
      >
        <Box className="overlay">
          {blockVideo && (
            <Box
              className="overlayTop"
              onClick={() => {
                setBlockVideo(false);
              }}
              height={height}
              width={width}
            >
              <Box>
                <img
                  src={images}
                  alt={` | This is one of my movies | `}
                  height={height}
                  width={width}
                />
              </Box>
            </Box>
          )}
          <iframe
            title={title}
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${srcSite}?rel=0&autoplay=1&loop=1&autopause=0&controls=1&mute=1&playlist=${srcSite}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Box>
    </Box>
  );
};
export default VideoComponent;
