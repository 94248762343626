import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Heading,
  ResponsiveContext,
  Text,
  TextInput,
} from 'grommet';
import celine from 'images/celine.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRedo } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';

function resolveData(data: string) {
  const power = data.slice(16, 17);
  // eslint-disable-next-line radix
  const powerBoolean = Boolean(parseInt(power));
  return powerBoolean;
}

const YoutubeChromecast = withRouter(({ history }) => {
  const size = React.useContext(ResponsiveContext);
  let height = '302';
  let width = '403';
  if (size === 'small' || size === 'xsmall') {
    height = '270';
    width = '360';
  }
  const [data85, setData85] = useState(['', false]);
  const [text, setText] = useState({ message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [secondsToRedirect, setSecondsToRedirect] = useState(2);
  const [thankYou] = useState('❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️!');

  async function switchLight(ip: string) {
    await fetch(`https://api.kiliankunkel.de/socket/${ip}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({}),
    });
  }

  async function sendText(uid: string) {
    const data = { id: uid };
    await fetch('https://api.kiliankunkel.de/youtube', {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  useEffect(() => {
    fetch('https://api.kiliankunkel.de/socket/192.168.178.85')
      .then((res) => res.json())
      .then((resData) => {
        setData85([resData.message, resolveData(resData.message)]);
      });
  }, []);

  if (submitted) {
    if (secondsToRedirect === 0) {
      history.push('/');
    }
    setTimeout(() => {
      setSecondsToRedirect(secondsToRedirect - 1);
    }, 1000);
  }

  return (
    <Box align="center" gap="small">
      {!submitted && (
        <Box align="center" pad="large" gap="small">
          <CheckBox
            label="turn on/off my tv + stereo"
            checked={data85[1] as boolean}
            onChange={(event) => {
              setData85([data85[0], !data85[1]]);
              switchLight('192.168.178.85');
            }}
            toggle
          />
          <Form
            onSubmit={() => {
              sendText(text.message);
              setText({ message: '' });
              setSubmitted(true);
            }}
            value={text}
            onChange={(nextValue) => setText(nextValue as { message: string })}
            onReset={() => setText({ message: '' })}
          >
            <FormField>
              <TextInput id="text-input-id" name="message" placeholder="UUID from youtube" />
            </FormField>
            <Box direction="row" gap="medium" justify="center">
              <Button type="reset" icon={<FontAwesomeIcon icon={faRedo} />} />
              <Button type="submit" label="Submit" icon={<FontAwesomeIcon icon={faPaperPlane} />} />
            </Box>
          </Form>
          <Text>https://www.youtube.com/watch?v=Fvj6PE3gN4o ➡ Fvj6PE3gN4o</Text>
          <Box margin={{ top: 'large' }}>
            <img src={celine} alt="me and Celine" height={height} width={width} />
          </Box>
        </Box>
      )}
      {submitted && (
        <Box>
          <Heading className="contentAnimation" textAlign="center">
            {thankYou}
          </Heading>
          <Text alignSelf="center">redirect in:</Text>
          <Text alignSelf="center">{secondsToRedirect}</Text>
        </Box>
      )}
    </Box>
  );
});
export default YoutubeChromecast;
