import ChartData from '../types/ChartData';

export const MOCK_DATA: ChartData[] = [
  { name: 'not loaded yet...', temperature: 0, humidity: 100, weight: 100 },
];

export const MOCK_DATA2: ChartData[] = [
  { name: 'test', temperature: -10, humidity: 100, weight: 100 },
  { name: 'test', temperature: 10, humidity: 100, weight: 100 },
  { name: 'test', temperature: 1000, humidity: 100, weight: 100 },
];
