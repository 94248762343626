import { Box, Button, Form, FormField, Heading, Text, TextInput } from 'grommet';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import Cookies from 'universal-cookie';

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps & RouteComponentProps> = (
  props: LoginProps & RouteComponentProps
) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [falseCredentials, setFalseCredentials] = useState(false);
  const history = useHistory();
  async function login() {
    await fetch('https://api.kiliankunkel.de/login', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.access_token) {
          const cookies = new Cookies();
          cookies.set('credentials', result);
          if (history.location.pathname === '/login') {
            props.history.push('admin');
          } else {
            history.go(0);
          }
          setFalseCredentials(false);
        } else {
          setFalseCredentials(true);
        }
      });
  }

  return (
    <Box margin="auto" width={{ max: 'large' }}>
      <Heading textAlign="center">login</Heading>
      <Form
        onSubmit={() => {
          login();
        }}
        value={credentials}
        onChange={(nextValue) =>
          setCredentials(nextValue as { username: string; password: string })
        }
        onReset={() => setCredentials({ username: '', password: '' })}
      >
        <FormField label="username">
          <TextInput id="text-input-id" name="username" autoComplete="username" type="username" />
        </FormField>
        <FormField label="passwort">
          <TextInput
            id="text-input-id"
            name="password"
            autoComplete="current-password"
            type="password"
          />
        </FormField>
        <Box direction="row" gap="medium" justify="center">
          <Button type="submit" label="login" />
        </Box>
        <Box direction="column" margin="large" justify="center">
          <Text alignSelf="center">or:</Text>
          <Button size="small" label="register" onClick={() => history.push('/register')} />
        </Box>
      </Form>
      <Box margin={{ top: 'medium' }}>
        {falseCredentials && <Text color="red">This was the wrong password.</Text>}
      </Box>
    </Box>
  );
};
export default withRouter(Login);
