import { Box, Heading, Text } from 'grommet';
import React from 'react';

interface VideoProps {}
const Me: React.FC<VideoProps> = () => (
  <Box margin="auto" width={{ max: 'large' }}>
    <Heading textAlign="center">about me</Heading>
    <Text textAlign="center">
      My name is Kilian Kunkel. <br />I was born in Tegernsee and in the following years of my life
      I, or rather my parents, were always drawn to the mountains and nature. That's why I now live
      on the edge of the Alps, an hour away from Munich, in Bad Tölz. The first four years of my
      "school-career" I attended the musical active Montessori school - I missed the musical part,
      but the active part has stayed with me. Since then I love to be outside and try to experience
      as much as possible. <br />
      Over time, I had made photography to one of my hobbies and I got closer and closer to
      videography. By now I have produced a lot of videos. With this website I want to share these
      fragments of my life with you. <br /> <br /> Cheers.
    </Text>
  </Box>
);
export default Me;
