import { Box, Button, Grid, Heading } from 'grommet';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
// eslint-disable-next-line import/no-cycle
import { NAMES, VIDEOS } from 'constants/routs';
import VideoComponent from './VideoComponent';
import 'transitions/transitions.css';

interface WorkProps {
  title: string;
  thumbnail: string;
  link: string;
}

function getName(location: string, next: number) {
  const found = NAMES.findIndex((element) => element.path === location);
  if (found === VIDEOS - 1 && next === 1) return NAMES[0].path;
  if (found === 0 && next === -1) return NAMES[VIDEOS - 1].path;
  const nextElement = NAMES[found + next];
  if (nextElement.path.slice(0, 5) === '/work') {
    return NAMES[found + next].path;
  }
  return NAMES[found].path;
}

const RightClick = withRouter(({ history, location }) => (
  <Button
    onClick={() => {
      history.push(`${getName(location.pathname, 1)}`);
    }}
    icon={<FontAwesomeIcon icon={faChevronRight} />}
    size="small"
    alignSelf="center"
  />
));
const LeftClick = withRouter(({ history, location }) => (
  <Button
    onClick={() => {
      history.push(`${getName(location.pathname, -1)}`);
    }}
    icon={<FontAwesomeIcon icon={faChevronLeft} />}
    size="small"
    alignSelf="center"
  />
));

const Work: React.FC<WorkProps> = ({ title, thumbnail, link }) => (
  <Box align="center">
    <Heading textAlign="center" margin={{ horizontal: '100px' }}>
      {title}
    </Heading>
    <Grid
      fill={true}
      rows={['100%', '100%']}
      columns={['10%', 'flex', '10%']}
      areas={[
        { name: 'left', start: [0, 0], end: [0, 0] },
        { name: 'video', start: [1, 0], end: [1, 0] },
        { name: 'right', start: [2, 0], end: [2, 0] },
      ]}
    >
      <Box className="center" gridArea="left">
        <LeftClick />
      </Box>
      <Box gridArea="video">
        <VideoComponent src={thumbnail} srcSite={link} title={title} />
      </Box>
      <Box className="center" gridArea="right">
        <RightClick />
      </Box>
    </Grid>
  </Box>
);
export default Work;
