import { Box, Button, ResponsiveContext } from 'grommet';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface LinksProps {
  setShowLayer: Function;
}

const Links: React.FunctionComponent<LinksProps & RouteComponentProps> = (
  props: LinksProps & RouteComponentProps
) => {
  const ROOT = encodeURI('/');
  const size = React.useContext(ResponsiveContext);
  function checkIfActualLocation(actual: string, toBeChecked: string) {
    if (actual === '/work') {
      // eslint-disable-next-line no-param-reassign
      toBeChecked = toBeChecked.slice(0, 5);
    }
    if (actual === toBeChecked) {
      return 'light-4';
    }

    return 'dark-2';
  }
  return (
    <>
      {(size === 'small' || size === 'xsmall') && (
        <Box direction="column" gap="small" margin={{ bottom: 'large' }}>
          <Button
            label="me"
            onClick={() => {
              props.history.push(ROOT);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/', props.location.pathname)}
          />
          <Button
            label="work"
            onClick={() => {
              props.history.push(`${ROOT}work/drosten`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/work', props.location.pathname)}
          />
          <Button
            label="my_smarthome"
            onClick={() => {
              props.history.push(`${ROOT}smarthome`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/smarthome', props.location.pathname)}
          />
          <Box margin={{ horizontal: '90px' }} background="dark-1" height="2px" />
          <Button
            label="message me"
            onClick={() => {
              props.history.push(`/message`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/message', props.location.pathname)}
          />
          <Button
            label="log in"
            onClick={() => {
              props.history.push(`/admin`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/admin', props.location.pathname)}
          />
        </Box>
      )}
      {(size === 'medium' || size === 'large') && (
        <Box direction="row" gap="small">
          <Button
            label="me"
            onClick={() => {
              props.history.push(ROOT);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/', props.location.pathname)}
          />
          <Button
            label="work"
            onClick={() => {
              props.history.push(`${ROOT}work/drosten`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/work', props.location.pathname)}
          />
          <Button
            label="my_smarthome"
            onClick={() => {
              props.history.push(`${ROOT}smarthome`);
              props.setShowLayer(false);
            }}
            size="small"
            alignSelf="center"
            color={checkIfActualLocation('/smarthome', props.location.pathname)}
          />
        </Box>
      )}
    </>
  );
};

export default withRouter(Links);
