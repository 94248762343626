import React from 'react';
import { usePageVisibility } from 'react-page-visibility';
import MetaTags from 'react-meta-tags';

interface TabTitleProps {
  title: string;
}

const TabTitle: React.FC<TabTitleProps> = ({ title }) => {
  let tabTitle = `About Me - Kilian Kunkel`;
  const isVisible = usePageVisibility();
  if (!isVisible) {
    tabTitle = 'Komm zurück! :)';
  }
  if (isVisible) {
    tabTitle = `${title} - Kilian Kunkel`;
  }
  return (
    <MetaTags>
      <title>{tabTitle}</title>
    </MetaTags>
  );
};
export default TabTitle;
