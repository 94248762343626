import { Box, Card, CardBody, CardHeader, ResponsiveContext, Text } from 'grommet';
import React from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import ChartData from 'types/ChartData';

interface LineChartComponentProps {
  data: ChartData[];
  datakey: string;
}

const CustomTooltip = ({ active, label, payload, datakey }) => {
  let value = '°C';
  if (datakey === 'humidity') {
    value = '%';
  }
  if (datakey === 'weight') {
    value = 'kg';
  }
  if (active) {
    return (
      <Card height="xsmall" width="xsmall">
        <CardHeader pad="xsmall">
          <Text textAlign="center" size="xsmall">
            {label}
          </Text>
        </CardHeader>
        <CardBody pad="xsmall" align="center">
          <Text weight="bold">
            {payload[0].value}
            {value}
          </Text>
        </CardBody>
      </Card>
    );
  }
  return null;
};

const LineChartComponent: React.FC<LineChartComponentProps> = ({ data, datakey }) => {
  let width = 600;
  let height = 300;
  const size = React.useContext(ResponsiveContext);
  if (size === 'small' || size === 'xsmall') {
    width = 300;
    height = 150;
  }
  return (
    <Box>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 60,
          left: 30,
          bottom: -20,
        }}
      >
        <XAxis dataKey="name" tick={false} dy={10} />
        <YAxis
          interval="preserveEnd"
          domain={[(dataMin) => Math.floor(dataMin), (dataMax) => Math.ceil(dataMax)]}
        />
        <Tooltip content={<CustomTooltip active label payload datakey={datakey} />} />
        <Line type="monotone" dataKey={datakey} stroke="#8884d8" dot={false} activeDot={{ r: 8 }} />
      </LineChart>
      <Box direction="row">
        <Box>
          <Text>{data[0].name.slice(0, 10)}</Text>
        </Box>
        <Box flex direction="row-reverse">
          <Text>{data[data.length - 1].name.slice(0, 10)}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default LineChartComponent;
