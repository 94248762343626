import { Box, Button, Heading, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import LineChartComponent from 'components/LineChartComponent';
import { MOCK_DATA } from '../mocks/temperatureData';

// TODO: There should be no float parsing
function transfromData(
  data: { id: number; temperature: string; time: string; humidity: string; weight: string }[]
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formattedData: any = [];
  let x = 0;
  for (let i = 0; i < data.length; i += 1) {
    formattedData[x] = {
      name: data[i].time,
      temperature: parseFloat(data[i].temperature),
      humidity: parseFloat(data[i].humidity),
      weight: parseFloat(data[i].weight),
    };
    x += 1;
  }
  return formattedData.reverse();
}

const SmartHome = () => {
  const types = ['temperature', 'humidity', 'weight'];

  const [actualDataString, setActualDataString] = useState('actual temperature: 20.0 °C');
  const [actualTime, setActualTime] = useState('');
  const [formattedDayData, setFormattedDayData] = useState(MOCK_DATA);
  const [formattedWeekData, setFormattedWeekData] = useState(MOCK_DATA);
  const [lastMonth, setLastMonth] = useState(MOCK_DATA);
  const [showActual, setShowActual] = useState(0);
  const [showNext, setShowNext] = useState(1);

  useEffect(() => {
    fetch('https://api.kiliankunkel.de/data/last/144')
      .then((res) => res.json())
      .then((resData) => {
        setActualDataString(`actual temperature: ${resData[0].temperature} °C`);
        setActualTime(resData[0].time);
        setFormattedDayData(transfromData(resData));
      });
    fetch('https://api.kiliankunkel.de/data/last/1008')
      .then((res) => res.json())
      .then((resData) => {
        setFormattedWeekData(transfromData(resData));
      });
    fetch('https://api.kiliankunkel.de/data/average/30')
      .then((res) => res.json())
      .then((resData) => {
        setLastMonth(transfromData(resData));
      });
  }, []);

  return (
    <Box align="center" gap="small">
      <Heading textAlign="center" margin={{ horizontal: '100px' }}>
        {actualDataString}
      </Heading>
      <Button
        label={`show ${types[showNext]}`}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const aktuelleDaten = (formattedDayData[formattedDayData.length - 1] as unknown) as any;
          let x = [types[showActual], aktuelleDaten.temperature, ' °C'];
          if (showActual === 0) x = [types[showNext], aktuelleDaten.humidity, ' %'];
          if (showActual === 1) x = [types[showNext], aktuelleDaten.weight, ' kg'];
          if (showActual === 2) x = [types[showNext], aktuelleDaten.temperature, ' °C'];
          setActualDataString(`actual ${x[0]}: ${x[1]} ${x[2]}`);
          if (showActual === 2) {
            setShowActual(0);
          } else {
            setShowActual(showActual + 1);
          }
          if (showNext === 2) {
            setShowNext(0);
          } else {
            setShowNext(showNext + 1);
          }
        }}
        size="small"
        alignSelf="center"
      />
      <Heading level="5">{types[showActual]} 24 hours:</Heading>
      <Box flex="grow" overflow="hidden">
        <LineChartComponent data={formattedDayData} datakey={types[showActual]} />
      </Box>
      <Heading level="5">{types[showActual]} 7 days:</Heading>
      <Box flex="grow" overflow="hidden">
        <LineChartComponent data={formattedWeekData} datakey={types[showActual]} />
      </Box>
      <Heading level="5" textAlign="center">
        {types[showActual]} last month (daily averages):
      </Heading>
      <Box flex="grow" overflow="hidden">
        <LineChartComponent data={lastMonth} datakey={types[showActual]} />
      </Box>
      <Box>
        <Text margin={{ top: 'small' }} size="xsmall">
          last update: {actualTime}
        </Text>
      </Box>
    </Box>
  );
};
export default SmartHome;
