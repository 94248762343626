import { Box, Heading, Image, ResponsiveContext } from 'grommet';
import React from 'react';
import monkey from 'images/monkey2.png';

const Error = () => {
  const size = React.useContext(ResponsiveContext);
  let width = 'medium';
  if (size === 'small') {
    width = 'small';
  }
  return (
    <Box align="center">
      <Heading textAlign="center">Oops...</Heading>
      <Heading textAlign="center" level="3">
        This page does not exist.
      </Heading>

      <Box width={width}>
        <Image fill src={monkey} />
      </Box>
    </Box>
  );
};
export default Error;
