import React, { useEffect, useState } from 'react';
import { Box, CheckBox, Heading } from 'grommet';

function resolveData(data: string) {
  const power = data.slice(16, 17);
  // eslint-disable-next-line radix
  const powerBoolean = Boolean(parseInt(power));
  return powerBoolean;
}

const Sockets = () => {
  const [data85, setData85] = useState(['', false]);
  const [data84, setData84] = useState(['', false]);
  const [data83, setData83] = useState(['', false]);

  async function switchLight(ip: string) {
    await fetch(`https://api.kiliankunkel.de/socket/${ip}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({}),
    });
  }

  useEffect(() => {
    fetch('https://api.kiliankunkel.de/socket/192.168.178.85')
      .then((res) => res.json())
      .then((resData) => {
        setData85([resData.message, resolveData(resData.message)]);
      });
    fetch('https://api.kiliankunkel.de/socket/192.168.178.84')
      .then((res) => res.json())
      .then((resData) => {
        setData84([resData.message, resolveData(resData.message)]);
      });
    fetch('https://api.kiliankunkel.de/socket/192.168.178.83')
      .then((res) => res.json())
      .then((resData) => {
        setData83([resData.message, resolveData(resData.message)]);
      });
  }, []);

  return (
    <Box align="center" gap="small">
      <Heading textAlign="center" margin={{ horizontal: '100px' }}>
        Sockets
      </Heading>
      <Box align="start" pad="large" gap="small">
        <CheckBox
          label="TV/ Stereo"
          checked={data85[1] as boolean}
          onChange={(event) => {
            setData85([data85[0], !data85[1]]);
            switchLight('192.168.178.85');
          }}
          toggle
        />
        <CheckBox
          label="Light"
          checked={data84[1] as boolean}
          onChange={(event) => {
            setData84([data84[0], !data84[1]]);
            switchLight('192.168.178.84');
          }}
          toggle
        />
        <CheckBox
          label="83"
          checked={data83[1] as boolean}
          onChange={(event) => {
            setData83([data83[0], !data83[1]]);
            switchLight('192.168.178.83');
          }}
          toggle
        />
      </Box>
    </Box>
  );
};
export default Sockets;
