import React from 'react';
import { Anchor, Box, Button, Footer, Grid, ResponsiveContext, Text } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faPaypal } from '@fortawesome/free-brands-svg-icons';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';

const BottomBar = withRouter(({ history, location }) => {
  const size = React.useContext(ResponsiveContext);
  function checkIfActualLocation(actual: string, toBeChecked: string) {
    if (actual === '/work') {
      // eslint-disable-next-line no-param-reassign
      toBeChecked = toBeChecked.slice(0, 5);
    }
    if (actual === toBeChecked) {
      return 'light-4';
    }

    return 'dark-2';
  }
  let direction = 'row';
  if (size === 'xsmall') {
    direction = 'column';
  }
  return (
    <Footer background="light-2" pad="xxsmall">
      <Grid
        fill={true}
        rows={['fill']}
        columns={['30%', '40%', '30%']}
        areas={[
          { name: 'left', start: [0, 0], end: [0, 0] },
          { name: 'middle', start: [1, 0], end: [1, 0] },
          { name: 'right', start: [2, 0], end: [2, 0] },
        ]}
      >
        <Box gridArea="left" animation="fadeIn">
          {(size === 'medium' || size === 'large') && (
            <Box fill margin="small" gap="small" direction="row">
              <Button
                label="message me"
                onClick={() => {
                  history.push(`/message`);
                }}
                size="small"
                alignSelf="center"
                color={checkIfActualLocation('/message', location.pathname)}
              />
              <Button
                label="log in"
                onClick={() => {
                  history.push(`/admin`);
                }}
                size="small"
                alignSelf="center"
                color={checkIfActualLocation('/admin', location.pathname)}
              />
            </Box>
          )}
        </Box>
        <Box gridArea="middle" align="center" justify="center">
          <Text size="xsmall">Kilian Kunkel</Text>
          <Box direction="row">
            <Button
              icon={<FontAwesomeIcon icon={faInstagram} />}
              href="https://www.instagram.com/kiliankunkel/"
              target="_blank"
              size="small"
              alignSelf="center"
            />
            <Button
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              href="mailto:kiliankunkel@gmx.de"
              size="small"
              alignSelf="center"
            />
            <Button
              icon={<FontAwesomeIcon icon={faLinkedin} />}
              target="_blank"
              href="https://www.linkedin.com/in/kilian-kunkel/"
              size="small"
              alignSelf="center"
            />
            <Button
              icon={<FontAwesomeIcon icon={faPaypal} />}
              target="_blank"
              href="https://www.paypal.com/paypalme/kiliankunkel"
              size="small"
              alignSelf="center"
            />
          </Box>
          <Box gap="small" direction={direction} align="center">
            <Anchor size="xsmall" label="AGB" color="dark-2" onClick={() => history.push('/agb')} />
            <Anchor
              size="xsmall"
              label="Datenschutz"
              color="dark-2"
              onClick={() => history.push('/datenschutz')}
            />
            <Anchor
              size="xsmall"
              label="Impressum"
              color="dark-2"
              onClick={() => history.push('/impressum')}
            />
            <Anchor
              size="xsmall"
              label="Widerruf"
              color="dark-2"
              onClick={() => history.push('/widerruf')}
            />
          </Box>
        </Box>
        <Box gridArea="right" />
      </Grid>
    </Footer>
  );
});

export default BottomBar;
