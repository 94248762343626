import { Box, Heading, Text } from 'grommet';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import dance from 'images/thumbnails/dance.png';
import VideoComponent from './VideoComponent';

interface AdminProps {}

const Admin: React.FunctionComponent<AdminProps & RouteComponentProps> = (
  props: AdminProps & RouteComponentProps
) => (
  <Box align="center" gap="small">
    <Heading textAlign="center">Welcome to the club! And thank's for your registration! </Heading>
    <Text>i'm currently working on some extra features for logged in members.</Text>
    <Text>
      meanwhile, you can watch this 10 hour video. maybe something will have changed by then.
    </Text>
    <VideoComponent src={dance} srcSite="UcRtFYAz2Yo" title="admin" />
  </Box>
);
export default withRouter(Admin);
