import { ThemeType, grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

const theme: ThemeType = {
  global: {
    breakpoints: {
      xsmall: {
        value: 576,
      },
      small: {
        value: 830,
      },
      medium: {
        value: 1200,
      },
    },
  },
};

export default deepMerge(theme, grommet);
